import {isFuture} from 'date-fns'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({slug}) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture ({publishedAt}) {
  return !isFuture(new Date(publishedAt))
}

export function getBlogUrl (slug) {
  return `/notes/${slug.current || slug}`
}

export function getLearningUrl (slug) {
  return `/learn/${slug.current || slug}`
}

export function getReviewUrl (slug) {
  return `/reviews/${slug.current || slug}`
}

export function getArticleUrl (slug) {
  return `/${slug.current || slug}`
}

export function getCategoryUrl (slug) {
  return `/notes/topics/${slug.current || slug}`
}

export function getLearningCategoryUrl (slug) {
  return `/learn/${slug.current || slug}`
}

export function getReviewCategoryUrl (slug) {
  return `/reviews/${slug.current || slug}`
}

export function buildImageObj (source = {asset: {}}) {
  const imageObj = {
    asset: {_ref: source.asset._ref || source.asset._id}
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText (blocks) {
  if (!blocks) {
    return ''
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}
