import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'
import {imageUrlFor} from '../lib/image-url'
import {buildImageObj} from '../lib/helpers'

function SEO ({description, lang, meta, keywords, title, image}) {

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const site = data.site
        const domain = site.siteMetadata.siteUrl
        const metaDescription =
          description || (data.sanity && data.sanity.description) || site.siteMetadata.description || ''
        const siteTitle = (data.sanity && data.sanity.title) || site.siteMetadata.title || ''
        const siteAuthor =
          site.siteMetadata.author || (data.sanity && data.sanity.author && data.sanity.author.name) ||
          ''
        const metaImage =
          image && image.asset
            ? imageUrlFor(buildImageObj(image)).width(1024).url()
            : `${domain}${data.file.childImageSharp.fluid.src}` || ''

        return (
          <Helmet
            htmlAttributes={{lang}}
            titleTemplate={title === siteTitle ? '%s' : `%s · ${siteTitle}`}
            title={title}
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:title',
                content: title === siteTitle ? title : title + ` · ${siteTitle}`
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                property: 'og:image',
                content: metaImage
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:creator',
                content: siteAuthor
              },
              {
                name: 'twitter:title',
                content: title === siteTitle ? title : title + ` · ${siteTitle}`
              },
              {
                name: 'twitter:description',
                content: metaDescription
              }
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: keywords.join(', ')
                  }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      buildTime(formatString: "yyyy-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        description
        author
      }
    }
    file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sanity: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      keywords
      author {
        name
      }
    }
  }
`